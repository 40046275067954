import React from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  CssBaseline,
  Divider,
  Link,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import loginBg from '../../../assets/signin/login-bg.jpg';
import meyzerlogoWhite from '../../../assets/signin/meyzerlogo-white.png';
import useBreakpoints from 'src/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#fff',
    flexWrap: 'no-wrap',
  },
  content: {
    flexGrow: 3,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    padding: theme.spacing(2),
  },
}));

function Layout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();

  return (
    <main>
      <Box
        display="flex"
        flexWrap="flex-nowrap"
        minHeight="100vh"
        width="100%"
        style={{
          backgroundColor: '#fff',
        }}
      >
        <CssBaseline />
        <Box display={smBelow ? 'none' : 'block'} flexGrow={1}>
          <Container
            style={{
              paddingTop: 30,
              // height: '100%',
              minHeight: '100%',
              backgroundColor: '#000',
              backgroundImage: `url(${loginBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom',
              backgroundSize: 'auto 70%',
            }}
          >
            <Box width="35%">
              <img src={meyzerlogoWhite} style={{ width: '100%' }} />
            </Box>
            <Box mt={6}>
              <Typography
                // variant="h1"
                align="left"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#FFFFFF',
                  fontSize: '40px',
                  fontWeight: 700,
                  lineHeight: '48px',
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <span>Supercharge</span> <span>Your</span>{' '}
                <span>Business.</span>
              </Typography>
            </Box>
          </Container>
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={3}>
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={3}
          >
            {children}
          </Box>
          <Box textAlign="center" mt={2} p={2}>
            <Typography color="textSecondary" variant="caption">
              © 2022 Meyzer Group &#8226;{' '}
              <Link
                href="https://www.meyzer360.com/terms-conditions/"
                target="_blank"
                style={{
                  color: '#000000',
                }}
              >
                Terms & Conditions
              </Link>{' '}
              &#8226;{' '}
              <Link
                href="https://www.meyzer360.com/privacy-policy/"
                target="_blank"
                style={{
                  color: '#000000',
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </main>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;
